import { Collection } from '@api/orderbook_api/v1/types.pb';
import { int } from '@api/utils';
import { Flex, FormatCryptoCurrency, Text } from '@components/primitives';
import { getZeroAddress } from '@utils/paymentTokens';
import { PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';

interface Props {
  collection?: Collection;
}

export const useCollectionMetrics = ({ collection }: Props) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 1255 });

  if (!collection) {
    return {};
  }

  const priceCss = {
    fontSize: isSmallDevice ? 13 : 15,
    fontWeight: isSmallDevice ? 500 : 600,
    lineHeight: 1,
  };

  const Metric = ({
    label,
    children,
  }: PropsWithChildren<{ label: string }>) => (
    <Flex direction='column' css={{ gap: '$1' }}>
      <Text
        color='subtle'
        css={{ fontSize: isSmallDevice ? 12 : 13, fontWeight: 500 }}
      >
        {label}
      </Text>
      {children}
    </Flex>
  );

  const { stats } = collection;
  const zeroSymbol = getZeroAddress(int(collection?.chainId))?.symbol;

  const floorPrice = (
    <Metric label='Floor Price'>
      <FormatCryptoCurrency
        css={priceCss}
        amount={collection?.stats?.floorAsk?.price?.amount?.raw}
        symbol={collection?.stats?.floorAsk?.price?.currency?.symbol}
        maximumFractionDigits={2}
        decimals={collection?.stats?.floorAsk?.price?.currency?.decimals}
        small={isSmallDevice}
      ></FormatCryptoCurrency>
    </Metric>
  );

  const topOffer = (
    <Metric label='Top Offer'>
      <FormatCryptoCurrency
        css={priceCss}
        amount={collection?.stats?.topOffer?.price?.amount?.raw}
        symbol={collection?.stats?.topOffer?.price?.currency?.symbol}
        maximumFractionDigits={2}
        decimals={collection?.stats?.floorAsk?.price?.currency?.decimals}
        small={isSmallDevice}
      ></FormatCryptoCurrency>
    </Metric>
  );

  const vol24 = (
    <Metric label='24h Vol'>
      <FormatCryptoCurrency
        css={priceCss}
        amount={collection?.stats?.volume?.oneDay}
        symbol={zeroSymbol}
        maximumFractionDigits={2}
        small={isSmallDevice}
      ></FormatCryptoCurrency>
    </Metric>
  );

  const volAll = (
    <Metric label='All Vol'>
      <FormatCryptoCurrency
        css={priceCss}
        symbol={zeroSymbol}
        amount={collection?.stats?.volume?.allTime}
        maximumFractionDigits={2}
        small={isSmallDevice}
      ></FormatCryptoCurrency>
    </Metric>
  );

  const { totalListings } = stats as any;
  const { totalSupply } = collection;
  const listedSupply = (
    <Metric label='Listed'>
      <Text css={priceCss}>
        {totalListings ?? '-'} / {totalSupply ?? '-'}
      </Text>
    </Metric>
  );

  const owners = (
    <Metric label='Owners'>
      <Text css={priceCss}>{stats?.ownerCount ?? '-'}</Text>
    </Metric>
  );

  const countSales24h = (
    <Metric label='24h Sales'>
      <Text css={priceCss}>{stats?.totalSalesOneDay ?? '-'}</Text>
    </Metric>
  );

  const avgSale24h = (
    <Metric label='24h Sales'>
      <FormatCryptoCurrency
        css={priceCss}
        amount={collection?.stats?.averages?.salePriceOneDay}
        symbol={zeroSymbol}
        maximumFractionDigits={2}
        small={isSmallDevice}
      ></FormatCryptoCurrency>
    </Metric>
  );

  return {
    floorPrice,
    topOffer,
    vol24,
    volAll,
    listedSupply,
    owners,
    countSales24h,
    avgSale24h,
  };
};
